import { faBuilding, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Radio, Stack, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { currentDuerpAtom, userShortAtom } from "../../../../../atoms/Atoms";
import { ICompany, ICompanyMembershipOrganism } from "../../../../../interfaces/Company";
import { IDuerpForm, IDuerpQuestion, IDuerpSection } from "../../../../../interfaces/DuerpForm";
import { AccompanyingStatus, ActionPriority, ProgressStatus } from "../../../../../interfaces/Form";
import { Role } from "../../../../../interfaces/User";
import colors from "../../../../../resources/cssConstant";
import { StatusCode } from "../../../../../resources/StatusCode";
import DuerpService from "../../../../../services/DuerpService";
import { stringifyErrorMessage } from "../../../../../utils/ConversionMethods";
import { downloadOiraForm } from "../../../../../utils/DownloadDocuments";
import { handleConflictOnDuerp } from "../../../../../utils/HandleToasterError";
import { LinkifyText } from "../../../../../utils/LinkifyText";
import GenericButton from "../../../../Generics/buttons/GenericButton";
import GenericIconButton from "../../../../Generics/buttons/GenericIconButton";
import GenericDialog from "../../../../Generics/GenericDialog/GenericDialog";
import GenericTextField from "../../../../Generics/GenericTextField/GenericTextField";
import AccompanimentChip from "../../../AccompanimentChip/AccompanimentChip";
import DuerpEndPage from "../DuerpEndPage/DuerpEndPage";
import DuerpValidationPage from "../DuerpValidationPage/DuerpValidationPage";

interface SectionDescriptionProps {
  section: IDuerpSection;
  sectionIndex: number;
  setJumpSection?: Dispatch<SetStateAction<boolean>>;
  company: ICompany;
  updateDuerp: (updatedDuerp?: IDuerpForm) => Promise<void>;
  loadingSave: boolean;
  setLoadingSave: (loading: boolean) => void;
  goToNextPage: () => void;
  updateSectionConcernedState: (isCompanyConcerned: boolean) => void;
}

const questionSchema = yup.object().shape({
  question: yup
    .string()
    .trim()
    .max(2000, "Le titre du risque ne doit pas excéder 2000 caractères.")
    .min(1, "Vous ne pouvez pas laisser le titre du risque vide.")
});

export default function SectionDescription({
  section,
  sectionIndex,
  setJumpSection,
  updateSectionConcernedState,
  company,
  updateDuerp,
  loadingSave,
  setLoadingSave,
  goToNextPage
}: SectionDescriptionProps) {
  const user = useAtomValue(userShortAtom);
  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [openFieldConfirmModal, setOpenFieldConfirmModal] = useState<boolean>(false);
  const [isCompanyConcerned, setIsCompanyConcerned] = useState<boolean>(section.companyConcerned ?? true);
  const [customQuestions, setCustomQuestions] = useState<IDuerpQuestion[]>(section.questions ?? []);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const isEndPage = sectionIndex === -4;
  const isFirstPage = sectionIndex === -2;
  const isValidationPage = sectionIndex === -3;

  const {
    register: registerQuestion,
    handleSubmit: handleSubmitQuestion,
    reset: resetQuestion,
    formState: { errors: errorsQuestion, isValid: isValidQuestion }
  } = useForm({
    resolver: yupResolver(questionSchema),
    mode: "onTouched"
  });

  useEffect(() => {
    resetQuestion();
    if (setJumpSection) {
      setJumpSection(!section.companyConcerned);
      setIsCompanyConcerned(section.companyConcerned);
    }
  }, []);

  useEffect(() => {
    setCustomQuestions(section.questions ?? []);
    if (setJumpSection) {
      setJumpSection(!section.companyConcerned);
      setIsCompanyConcerned(section.companyConcerned);
    }
  }, [section]);

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setIsCompanyConcerned(value === "true");
    updateSectionConcernedState(value === "true");
    if (setJumpSection) {
      setJumpSection(value === "false");
    }
  };

  const addQuestion = async (data: FieldValues) => {
    setLoadingSave(true);
    const updatedDuerp = { ...duerp };
    const newQuestions = [...customQuestions];
    const newQuestion: IDuerpQuestion = {
      actionPriority: ActionPriority.HIGH,
      companyComment: "",
      currentMeasures: [],
      description: "",
      expertComment: "",
      futureMeasures: [],
      oiraQuestionUuid: "",
      riskManaged: false,
      title: data.question,
      completed: false,
      uuid: "",
      addByExpert: false
    };
    newQuestions.push(newQuestion);
    setCustomQuestions(newQuestions);
    updatedDuerp.sections[sectionIndex].questions = newQuestions;
    const res = await DuerpService().updateDuerp(updatedDuerp);
    if (res.status === StatusCode.OK) {
      await updateDuerp(res.data);
      resetQuestion();
    } else if (res.status === StatusCode.CONFLICT) {
      handleConflictOnDuerp(res);
    }
    setLoadingSave(false);
  };

  const deleteQuestion = async (questionIndex: number) => {
    setLoadingSave(true);
    const updatedDuerp = { ...duerp };
    const updatedQuestionList = customQuestions;
    updatedQuestionList.splice(questionIndex, 1);
    updatedDuerp.sections[sectionIndex].questions = updatedQuestionList;
    setCustomQuestions(updatedQuestionList);
    const res = await DuerpService().updateDuerp(updatedDuerp);
    if (res.status === StatusCode.OK) {
      await updateDuerp(res.data);
    } else if (res.status === StatusCode.CONFLICT) {
      handleConflictOnDuerp(res);
    }
    setLoadingSave(false);
  };

  const renderCustomSection = () => (
    <Stack>
      <Stack component="form" direction="row" spacing={2} sx={{ mr: 5, mb: 5 }}>
        <GenericTextField
          label="Titre du risque personnalisé"
          error={!!errorsQuestion.question}
          helperText={stringifyErrorMessage(errorsQuestion.question)}
          id="question"
          placeholder="Saisir un titre de risque personnalisé"
          register={registerQuestion("question")}
        />
        <GenericButton
          text="Ajouter ce risque"
          onClick={handleSubmitQuestion(addQuestion)}
          disabled={loadingSave || !isValidQuestion || duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
        />
      </Stack>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Risques personnalisés ajoutés
      </Typography>
      {customQuestions.length === 0 ? (
        <Typography variant="body1">Aucun risque ajouté</Typography>
      ) : (
         customQuestions.map((question, index) => (
           <Stack direction="row" key={question.uuid} alignItems="center">
             <Typography variant="body1">{question.title}</Typography>
             <GenericIconButton
               size="sm"
               onClick={() => deleteQuestion(index)}
               icon={faTrash}
               tooltip={
                 user.role === Role.COMPANY_USER && question.addByExpert
                 ? "Vous ne pouvez pas supprimer un risque ajouté par un préventeur"
                 : "Supprimer ce risque"
               }
               disabled={loadingSave || user.role === Role.COMPANY_USER && question.addByExpert}
               color={colors.deleteIcon}
             />
           </Stack>
         ))
       )}
    </Stack>
  );

  const changeToFieldAccompaniement = async () => {
    const updatedDuerp = { ...duerp };
    updatedDuerp.accompanyingStatus = AccompanyingStatus.FIELD_ACCOMPANIMENT;
    const res = await DuerpService().updateDuerp(updatedDuerp);
    if (res.status === StatusCode.OK) {
      toast.success("Votre DUERP a bien changé de statut.");
      setDuerp(res.data);
    } else if (res.status === StatusCode.CONFLICT) {
      handleConflictOnDuerp(res);
    } else {
      toast.error(
        duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
        ? "Vous ne pouvez pas modifier un DUERP archivé ou finalisé."
        : "Impossible de valider ce DUERP pour le moment."
      );
    }
    setOpenFieldConfirmModal(false);
  };

  const renderConfirmFieldAccompaniementModal = () => (
    <GenericDialog
      openDialog={openFieldConfirmModal}
      handleClose={() => setOpenFieldConfirmModal(false)}
      title="Confirmation de l'Accompagnement Terrain"
      onValid={changeToFieldAccompaniement}
      confirmLabel="Confirmer"
    >
      <Typography variant="body1">
        Souhaitez-vous réellement changer le statut d'accompagnement du DUERP en "Accompagnement Terrain" ?
      </Typography>
    </GenericDialog>
  );

  const renderFirstPage = () => {
    const companyInfo = "Informations de l'entreprise";
    const companyName = "Raison sociale ou dénomination : ";
    const companyOrganism = "Votre service de prévention et de santé au travail : ";
    const membershipNumber = "Numéro d'adhérent SPSTI : ";
    const collaboratorsTitle = "Collaborateurs de l'entreprise : ";
    const membershipOrganism =
      company?.membershipOrganization === ICompanyMembershipOrganism.OTHER
      ? "Autre organisme"
      : company?.membershipOrganization.toString().replace("_", " ");
    const collaboratorsList = company.collaborators.concat(company.externalCollaborators);
    return (
      <Stack spacing={2} width="50%">
        {renderConfirmFieldAccompaniementModal()}
        <Typography variant="question" sx={{ mb: 3 }}>
          {companyInfo}
        </Typography>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{companyName}</Typography>
          <Typography variant="body1">{company?.companyName}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{companyOrganism}</Typography>
          <Typography variant="body1">{membershipOrganism}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{membershipNumber}</Typography>
          <Typography variant="body1">{company?.membershipNumber}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Typography variant="subQuestion">{collaboratorsTitle}</Typography>
          <Stack alignItems="flex-end" justifyContent="flex-end">
            {collaboratorsList.map((collaborator) => (
              <Typography key={collaborator} variant="body1">
                {collaborator}
              </Typography>
            ))}
          </Stack>
        </Stack>
        {user.role !== Role.COMPANY_USER && (
          <Stack direction="row" alignItems="baseline" justifyContent="space-between">
            <Typography variant="subQuestion">Statut d'accompagnement :</Typography>
            <Box>
              <AccompanimentChip accompanimentType={duerp.accompanyingStatus} />
              {duerp.accompanyingStatus !== AccompanyingStatus.FIELD_ACCOMPANIMENT && (
                <GenericIconButton
                  onClick={() => setOpenFieldConfirmModal(true)}
                  icon={faBuilding}
                  color={colors.primary}
                  disabled={
                    duerp.accompanyingStatus !== AccompanyingStatus.ACCOMPANIED &&
                    duerp.progressStatus !== ProgressStatus.NOT_FINALIZED
                  }
                  tooltip={
                    duerp.accompanyingStatus === AccompanyingStatus.ACCOMPANIED
                    ? "Passer en accompagnement terrain"
                    : ""
                  }
                />
              )}
            </Box>
          </Stack>
        )}
      </Stack>
    );
  };

  const renderOptionalQuestion = () => (
    <Stack spacing={3}>
      <Typography variant="question">Votre entreprise est-elle concernée par cette étape ?</Typography>

      <Stack spacing={1} alignItems="flex-start">
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
            value="true"
            checked={isCompanyConcerned}
            onChange={handleRadioChange}
          />
          <Typography variant="body1">Oui</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Radio
            disabled={duerp.progressStatus !== ProgressStatus.NOT_FINALIZED}
            value="false"
            checked={!isCompanyConcerned}
            onChange={handleRadioChange}
          />
          <Typography variant="body1">Non</Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  const downloadForm = async () => {
    setLoadingForm(true);
    await downloadOiraForm(duerp.oiraFormUuid);
    setLoadingForm(false);
  };

  const renderInternalSection = (isValidationPage, company, section) => {
    return isValidationPage ? (
      <DuerpValidationPage companyUuid={company.uuid} goToNextPage={goToNextPage} />
    ) : (
             <Box>
               <Typography variant="h3" sx={{ mb: 5 }}>
                 {section.title}
               </Typography>
               <Typography variant="body1" sx={{ whiteSpace: "pre-line", marginY: 10, width: "70%" }}>
                 {LinkifyText(section.description)}
               </Typography>
               {section.customRisksSection ? renderCustomSection() : renderSection()}
             </Box>
           );
  };

  const renderSection = () => (
    <>
      {section.optional && renderOptionalQuestion()}
      {isFirstPage && renderFirstPage()}
      {section.title === "Implication des salariés" && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GenericButton
            text="Télécharger l'évaluation"
            icon={faDownload}
            onClick={() => downloadForm()}
            color={colors.primary}
            disabled={loadingForm}
          />
        </Box>
      )}
    </>
  );

  return isEndPage ? (
    <DuerpEndPage companyUuid={company.uuid} />
  ) : (
           renderInternalSection(isValidationPage, company, section)
         );
}

SectionDescription.defaultProps = {
  setJumpSection: undefined
};

import { Grid, Stack } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { currentDuerpAtom, userAtom } from "../../../atoms/Atoms";
import { ICompany } from "../../../interfaces/Company";
import { IDuerpForm, IDuerpQuestion, IDuerpSection } from "../../../interfaces/DuerpForm";
import { ProgressStatus } from "../../../interfaces/Form";
import { Role } from "../../../interfaces/User";
import { emptyCompany, emptyDuerp, emptyQuestion } from "../../../resources/AppConstants";
import { backOfficeRoutes, frontOfficeRoutes } from "../../../resources/Routes";
import { StatusCode } from "../../../resources/StatusCode";
import CompanyService from "../../../services/CompanyService";
import DuerpService from "../../../services/DuerpService";
import { flattenDuerp, INavigationElement, IPosition } from "../../../utils/DuerpTransformation";
import { handleConflictOnDuerp } from "../../../utils/HandleToasterError";
import GenericButton from "../../Generics/buttons/GenericButton";
import AccompanimentChip from "../AccompanimentChip/AccompanimentChip";
import Header from "../Header/Header";
import ProgressChip from "../ProgressChip/ProgressChip";
import DuerpMenuOptions from "./containers/DuerpMenuOptions/DuerpMenuOptions";
import DuerpQuestion from "./containers/DuerpQuestion/DuerpQuestion";
import DuerpQuestionSkeleton from "./containers/DuerpQuestion/DuerpQuestionSkeleton";
import DuerpStepper from "./containers/DuerpStepper/DuerpStepper";
import SectionDescription from "./containers/SectionDescription/SectionDescription";
import SkeletonDuerp from "./SkeletonDuerp";

export default function Duerp() {
  const navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const duerpRef = useRef<HTMLInputElement>(null);
  const [duerp, setDuerp] = useAtom(currentDuerpAtom);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [question, setQuestion] = useState<IDuerpQuestion>(emptyQuestion);
  const [flatDuerp, setFlatDuerp] = useState<INavigationElement[]>([]);
  const [flatIndex, setFlatIndex] = useState<number>(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const [progressValue, setProgressValue] = useState<number>(0);
  const [jumpSection, setJumpSection] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>(emptyCompany);

  const { refNumber } = useParams();

  const setFullyCompleted = (flattenedDuerp: INavigationElement[]) => {
    const newCompleted = { ...completed };
    flattenedDuerp.forEach((element, index) => {
      newCompleted[index] = true;
    });
    setCompleted(newCompleted);
    setProgressValue(100);
  };

  const isQuestion = (listElement: IDuerpQuestion | IDuerpSection) => "currentMeasures" in listElement;

  const updateCompletedStatus = () => {
    const newCompleted = { ...completed };
    flatDuerp.forEach((navigationElement, index) => {
      newCompleted[index] =
        navigationElement.position.currentSectionIndex > -1 ? navigationElement.element.completed : false;
    });
    const completedArray: boolean[] = Object.values(newCompleted);
    const numberOfTrueValues: number = completedArray.reduce(
      (sum: number, isQuestionCompleted: boolean) => (isQuestionCompleted ? sum + 1 : sum),
      0
    );
    // we -4 because we remove the sections that are not part of the DUERP
    setProgressValue(Math.round((100 * numberOfTrueValues) / (flatDuerp.length - 4)));
    setCompleted(newCompleted);
  };

  const fetchDuerpData = async (updatedDuerp?: IDuerpForm) => {
    setLoading(true);
    let updatedData: IDuerpForm | undefined = updatedDuerp;
    if (!updatedData) {
      const res = await DuerpService().getDuerpByReference(refNumber !== undefined ? refNumber.toString() : "");
      if (res.data) updatedData = res.data;
    }

    if (updatedData) {
      const { companyUuid } = updatedData;
      const companyRes = await CompanyService().getCompanyById(companyUuid);
      setCompany(companyRes.data);
    }
    const retrievedDuerp = updatedData ?? emptyDuerp;
    setDuerp(retrievedDuerp);
    const flattenedDuerp = flattenDuerp(retrievedDuerp);

    setFlatDuerp(flattenedDuerp);
    if (duerp.progressStatus === ProgressStatus.FINALIZED) {
      setFullyCompleted(flatDuerp);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchDuerpData().then(
      () => {
        setProgressValue(0);
        setLoading(false);
      },
      () => {
        toast.error("Aucune donnée à afficher.");
        setLoading(false);
        navigate(user.role === Role.COMPANY_USER ? frontOfficeRoutes.home : backOfficeRoutes.home);
      }
    );
  }, []);

  const updateQuestionState = (index: number) => {
    const element = flatDuerp[index]?.element ?? null;
    if (element === null) return;
    if (isQuestion(element)) {
      setQuestion(element as IDuerpQuestion);
    } else {
      setQuestion(emptyQuestion);
    }
  };

  useEffect(() => {
    duerpRef.current?.scrollIntoView({ behavior: "smooth" });
    updateQuestionState(flatIndex);
  }, [flatIndex]);

  useEffect(() => {
    if (duerp.progressStatus === ProgressStatus.FINALIZED) {
      setFullyCompleted(flatDuerp);
    } else {
      updateCompletedStatus();
    }
  }, [duerp]);

  const checkSectionHasQuestions = (updatedDuerp: IDuerpForm, questionPosition: IPosition) => {
    const subsections = updatedDuerp.sections[questionPosition.currentSectionIndex].subSections;
    if (subsections) {
      return subsections[questionPosition.currentSubSectionIndex].questions;
    }
    return updatedDuerp.sections[questionPosition.currentSectionIndex].questions;
  };

  const saveCurrentQuestionAnswers = async (): Promise<IDuerpForm> => {
    const updatedDuerp = { ...duerp };
    const completedQuestion = { ...question };
    completedQuestion.completed = true;
    const questionPosition = flatDuerp[flatIndex].position;
    const questionList = checkSectionHasQuestions(updatedDuerp, questionPosition);
    // to update question in updatedDuerp object
    if (questionList) {
      questionList[questionPosition.currentQuestionIndex] = completedQuestion;
    }

    const res = await DuerpService().updateDuerp(updatedDuerp);
    if (res.status === StatusCode.OK) {
      setDuerp(res.data);
      setFlatDuerp(flattenDuerp(res.data));
      return res.data;
    } else if (res.status === StatusCode.CONFLICT) {
      handleConflictOnDuerp(res);
    } else {
      toast.error(
        "Impossible de sauvegarder vos réponses pour le moment. Veuillez rafraîchir la page et ne pas continuer l’édition des autres questions."
      );
    }
    return Promise.reject(new Error(`Request failed with error: ${res.status}`));
  };

  const saveCurrentSectionCompletion = async (): Promise<IDuerpForm> => {
    const updatedDuerp = { ...duerp };
    const completedSectionPosition = { ...flatDuerp[flatIndex].position };
    const subsections = updatedDuerp.sections[completedSectionPosition.currentSectionIndex].subSections;
    if (completedSectionPosition.currentSubSectionIndex >= 0 && subsections) {
      subsections[completedSectionPosition.currentSubSectionIndex].completed = true;
    } else {
      updatedDuerp.sections[completedSectionPosition.currentSectionIndex].completed = true;
    }

    const res = await DuerpService().updateDuerp(updatedDuerp);
    if (res.status === StatusCode.OK) {
      setDuerp(res.data);
      setFlatDuerp(flattenDuerp(res.data));
      return res.data;
    } else if (res.status === StatusCode.CONFLICT) {
      handleConflictOnDuerp(res);
    } else {
      toast.error(
        "Impossible de sauvegarder vos réponses pour le moment. Veuillez rafraîchir la page et ne pas continuer l’édition des autres sections."
      );
    }
    return Promise.reject(new Error(`Request failed with error: ${res.status}`));
  };

  const updateSectionConcernedState = (isConcerned: boolean, duerpToUpdate?: IDuerpForm) => {
    const updatedDuerp = duerpToUpdate ?? { ...duerp };

    const { position } = flatDuerp[flatIndex];
    if (position.currentSectionIndex > -1) {
      const retrievedSections =
        updatedDuerp.sections[position.currentSectionIndex].subSections ?? updatedDuerp.sections;
      if (position.currentSubSectionIndex > -1) {
        retrievedSections[position.currentSubSectionIndex].companyConcerned = isConcerned;
      } else {
        updatedDuerp.sections[position.currentSectionIndex].companyConcerned = isConcerned;
      }
    }
    setDuerp(updatedDuerp);
    setFlatDuerp(flattenDuerp(updatedDuerp));
  };

  const goToNextSection = (duerpToUpdate?: IDuerpForm) => {
    const currentSection = flatDuerp[flatIndex].element as IDuerpSection;
    let increment = 0;
    if (currentSection.subSections) {
      increment = currentSection.subSections.length + flatIndex;
      currentSection.subSections.forEach((subsection) => {
        increment += subsection.questions?.length ?? 0;
      });
    } else if (!currentSection.subSections && currentSection.questions) {
      increment = currentSection.questions.length + flatIndex;
    }

    const nextSection = (navElement: INavigationElement) => {
      const section = navElement.element as IDuerpSection;
      return (section.questions || section.subSections) && flatDuerp.indexOf(navElement) > increment;
    };

    const nextIndex = flatDuerp.findIndex(nextSection) ?? flatDuerp.length;
    updateSectionConcernedState(false, duerpToUpdate);
    setFlatIndex(nextIndex);
    updateQuestionState(nextIndex);
  };

  const saveAnswers = async (): Promise<IDuerpForm | undefined> => {
    let hasToUpdate: boolean = true;
    let updatedDuerp: IDuerpForm | undefined;

    if (isQuestion(flatDuerp[flatIndex].element)) {
      updatedDuerp = await saveCurrentQuestionAnswers();
    } else if (flatDuerp[flatIndex].position.currentSectionIndex >= 0) {
      updatedDuerp = await saveCurrentSectionCompletion();
    } else {
      hasToUpdate = false;
    }

    if (updatedDuerp || !hasToUpdate) {
      updateCompletedStatus();
      return updatedDuerp;
    }
    return Promise.reject(new Error(`Couldn't update answers for: ${flatIndex}`));
  };

  const goToNextPage = async () => {
    setLoading(true);
    let updatedDuerp: IDuerpForm | undefined;
    if (duerp.progressStatus === ProgressStatus.NOT_FINALIZED) {
      updatedDuerp = await saveAnswers();
    }

    if (jumpSection) {
      goToNextSection(updatedDuerp);
      setJumpSection(false);
    } else {
      updateSectionConcernedState(true, updatedDuerp);
      setFlatIndex(flatIndex + 1);
      updateQuestionState(flatIndex + 1);
    }
    setLoading(false);
  };

  const handleNewFlatIndex = (sectionIsConcerned: boolean, sectionElement: IDuerpSection) => {
    if (sectionIsConcerned) {
      setFlatIndex(flatIndex - 1);
      updateQuestionState(flatIndex - 1);
    } else {
      const index = flatDuerp.findIndex((navElement) => navElement.element.uuid === sectionElement.uuid);
      setFlatIndex(index);
      updateQuestionState(index);
    }
  };

  const goToPreviousSection = () => {
    // checks where to go if previous section was ignored
    const previousElement = flatDuerp[flatIndex - 1].element;
    const elementSectionIndex = flatDuerp[flatIndex - 1].position.currentSectionIndex;
    const elementSubSectionIndex = flatDuerp[flatIndex - 1].position.currentSubSectionIndex;
    if (isQuestion(previousElement)) {
      const sectionIsConcerned = duerp.sections[elementSectionIndex].companyConcerned;
      const subsections = duerp.sections[elementSectionIndex].subSections;
      if (elementSubSectionIndex > -1 && subsections) {
        const subSectionIsConcerned = subsections[elementSubSectionIndex].companyConcerned;
        handleNewFlatIndex(subSectionIsConcerned, subsections[elementSubSectionIndex]);
      } else {
        handleNewFlatIndex(sectionIsConcerned, duerp.sections[elementSectionIndex]);
      }
    } else {
      setFlatIndex(flatIndex - 1);
      updateQuestionState(flatIndex - 1);
    }
  };

  const goToPreviousPage = () => {
    setLoading(true);
    goToPreviousSection();
    setLoading(false);
  };

  const renderFormContent = () => {
    const { element, position } = flatDuerp[flatIndex];
    // DOC : section with index -2 is Duerp general information. section with index -1 is "implication des salariés".
    // these are special display only sections and are not really "part" of the generated duerp document
    if (isQuestion(element)) {
      return question === emptyQuestion ? (
        <DuerpQuestionSkeleton />
      ) : (
               <DuerpQuestion question={question} setQuestion={setQuestion} />
             );
    }

    return (
      <>
        {!company && <SkeletonDuerp />}
        {company && (
          <SectionDescription
            section={element as IDuerpSection}
            sectionIndex={position.currentSectionIndex}
            setJumpSection={setJumpSection}
            updateSectionConcernedState={updateSectionConcernedState}
            company={company}
            updateDuerp={fetchDuerpData}
            loadingSave={loadingSave}
            setLoadingSave={setLoadingSave}
            goToNextPage={goToNextPage}
          />
        )}
      </>
    );
  };

  const buttonPosition = () => (flatIndex === 0 ? "flex-end" : "space-between");

  const nextButtonText = () => {
    if (duerp.progressStatus === ProgressStatus.NOT_FINALIZED) {
      if (flatIndex === flatDuerp.length - 3) return "Enregistrer et passer à l'étape de validation";
      return "Enregistrer et continuer";
    }
    return "Suivant";
  };

  const goToHomePage = () => {
    if (user.role === Role.COMPANY_USER) navigate(frontOfficeRoutes.home);
    else navigate(backOfficeRoutes.home);
  };

  return (
    <Stack ref={duerpRef} sx={{ height: "100%" }}>
      <Header marginBottom={0} title={`Duerp - ${duerp.title} - ${duerp.reference}`}>
        <AccompanimentChip accompanimentType={duerp.accompanyingStatus} />
        <ProgressChip progressType={duerp.progressStatus} />
        {!loading && <DuerpMenuOptions document={duerp} companyUuid={company.uuid} />}
      </Header>
      <Grid sx={{ height: "100%" }} container spacing={2}>
        <Grid item xs={2}>
          <DuerpStepper
            flatDuerp={flatDuerp}
            flatIndex={flatIndex}
            setFlatIndex={setFlatIndex}
            completed={completed}
            progressValue={progressValue}
          />
        </Grid>
        <Grid item xs sx={{ mr: 2, my: 2, display: "flex", flexDirection: "column" }}>
          {loading ? (
            <SkeletonDuerp />
          ) : (
             <>
               {renderFormContent()}
               <Stack direction="row" alignItems="center" justifyContent={buttonPosition} sx={{ mt: 5 }}>
                 {flatIndex !== 0 && <GenericButton onClick={() => goToPreviousPage()} text="Précédent" />}
                 {flatIndex < flatDuerp.length - 1 && (
                   <GenericButton onClick={goToNextPage} text={nextButtonText()} disabled={loadingSave} />
                 )}
                 {flatIndex === flatDuerp.length - 1 && (
                   <GenericButton onClick={goToHomePage} text="Retour à l'espace entreprise" />
                 )}
               </Stack>
             </>
           )}
        </Grid>
      </Grid>
    </Stack>
  );
}
